import React, { useState } from 'react';
import { TextField, InputAdornment, Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SearchBar.css';

const SearchBar = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [peopleCount, setPeopleCount] = useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        onSearch({ searchTerm: value, startDate, endDate, peopleCount });
    };

    const handlePeopleChange = (event) => {
        const value = event.target.value ? parseInt(event.target.value, 10) : ''; // Преобразование в число
        setPeopleCount(value);
        onSearch({ searchTerm, startDate, endDate, peopleCount: value });
    };

    const clearSearch = () => {
        setSearchTerm('');
        setPeopleCount('');
        setStartDate(null);
        setEndDate(null);
        onSearch({ searchTerm: '', startDate: null, endDate: null, peopleCount: '' });
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        onSearch({ searchTerm, startDate: start, endDate: end, peopleCount });
    };

    return (
        <Box className="search-bar-container">
            <Box className="search-bar-row">
                <TextField
                    fullWidth
                    placeholder="Регион/Город/Название тура"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={clearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    className="search-input"
                />

                <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText="Даты"
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    customInput={
                        <TextField
                            fullWidth
                            className="date-picker-container"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarTodayIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                />

                <TextField
                    fullWidth
                    placeholder="Сколько человек поедет?"
                    variant="outlined"
                    type="number"
                    value={peopleCount}
                    onChange={handlePeopleChange}
                    className="people-filter-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                👤
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};

export default SearchBar;
