import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Стили для lightbox

const TourGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Статус загрузки

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLoading(true); // Включаем загрузку при открытии
    setIsOpen(true);
  };

  const handleImageLoad = () => {
    setLoading(false); // Выключаем индикатор загрузки, как только изображение загружено
  };

  // Проверяем наличие изображений
  if (!images || images.length === 0) {
    return <p>Нет доступных изображений</p>;
  }

  return (
    <div className="gallery-container">
      {/* Основное изображение слева */}
      <div className="gallery-main">
        <img
          src={images[0]}
          alt="Main"
          onClick={() => openLightbox(0)}
        />
      </div>

      {/* Миниатюры справа в 2 строки и 2 столбца */}
      <div className="gallery-thumbnails">
        {images.slice(1, 5).map((image, index) => (
          <div
            key={index + 1}
            className="thumbnail"
            onClick={() => openLightbox(index + 1)}
          >
            <img src={image} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Lightbox для просмотра изображений */}
      {isOpen && images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            setLoading(true); // Включаем индикатор при перелистывании
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Включаем индикатор при перелистывании
            setPhotoIndex((photoIndex + 1) % images.length);
          }}
          imageLoadErrorMessage="Ошибка загрузки"
          onImageLoad={handleImageLoad} // Вызываем при загрузке изображения
        />
      )}

      {/* Индикатор загрузки */}
      {loading && isOpen && (
        <div className="loading-indicator">Загрузка...</div>
      )}
    </div>
  );
};

export default TourGallery;
