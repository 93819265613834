import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Container, Typography, Fab } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import SearchBar from './SearchBar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './TourCard.css';

const TourList = () => {
    const [tours, setTours] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredTours, setFilteredTours] = useState([]);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        fetchAllTours();

        const handleScroll = () => {
            setShowScrollToTop(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fetchAllTours = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tours`);
            const data = await response.json();
            setTours(data);
            setFilteredTours(data);
        } catch (error) {
            console.error('Ошибка загрузки туров:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = ({ searchTerm, startDate, endDate, peopleCount }) => {
        const filtered = tours.filter((tour) => {
            if (!Array.isArray(tour.dates) || tour.dates.length === 0) {
                return false;
            }

            const matchesSearch = searchTerm
                ? tour.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  tour.region.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  tour.cityStart.toLowerCase().includes(searchTerm.toLowerCase())
                : true;

            const matchesDateRange = startDate && endDate
                ? tour.dates.some(({ dateStart, dateFinish }) => {
                      const start = new Date(dateStart);
                      const end = new Date(dateFinish);
                      return start <= endDate && end >= startDate;
                  })
                : true;

            const matchesPeopleCount = peopleCount
                ? tour.dates.some(({ freeMest }) => parseInt(freeMest, 10) >= peopleCount)
                : true;

            return matchesSearch && matchesDateRange && matchesPeopleCount;
        });

        setFilteredTours(filtered);
    };

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Container sx={{ marginTop: '30px', paddingBottom: '50px' }}>
            <SearchBar onSearch={handleSearch} />

            {loading ? (
                <div className="loading-container">
                    <div className="double-ring"></div>
                </div>
            ) : (
                <Grid container spacing={4}>
                    {filteredTours.length > 0 ? (
                        filteredTours.map((tour) => (
                            <Grid item xs={12} sm={6} md={4} key={tour.turId}>
                                <Card
                                    component={Link}
                                    to={`/tours/${tour.turId}`}
                                    className="card-container"
                                >
                                    <Slider {...sliderSettings}>
                                        {tour.photoGallery.map((photo, index) => (
                                            <div key={index}>
                                                <img
                                                    src={photo || 'https://via.placeholder.com/300'}
                                                    alt={`Фото тура ${tour.name}`}
                                                    className="card-image"
                                                />
                                            </div>
                                        ))}
                                    </Slider>

                                    <CardContent className="card-content">
                                        <Typography gutterBottom variant="h5">
                                            {tour.name}
                                        </Typography>
                                        <Typography variant="h6" sx={{ color: 'orange' }}>
                                            {tour.cost} ₽
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            className="card-description"
                                        >
                                            {tour.smallDescriptions}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center', marginTop: '20px' }}>
                            На данный момент туров нет
                        </Typography>
                    )}
                </Grid>
            )}

{showScrollToTop && (
    <Fab
        size="medium" // Изменяем размер на medium (как в TourPage)
        onClick={handleScrollToTop}
        sx={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
            backgroundColor: 'orange', // Оранжевый цвет кнопки
            color: 'white', // Белый цвет иконки
            '&:hover': {
                backgroundColor: '#FF8C00', // Более темный оттенок при наведении
            },
        }}
    >
        <KeyboardArrowUpIcon />
    </Fab>
            )}
        </Container>
    );
};

export default TourList;
